import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";
import LineItem from "./LineItem";
import ResumeCard from "./ResumeCard";

const PrivacyPolicy = () => {
  const { lineArray, resumeArray } = UseData();

  return (
    <>
      <PageTitle title="Foxy's World"></PageTitle>
      {/* End pagetitle */}

      <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
        <div data-aos="fade">
          <div className="container  sm:px-5 md:px-10 lg:px-20">
            <div className="py-12 px-12">
              {/*page title here */}
              <h2 className="after-effect after:left-80 mb-[40px] mt-12 lg:mt-0">
              Privacy Policy
              </h2>
              <div className="">
                {/* resume items map */}
                
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>1. ACKNOWLEDGMENT OF THIS POLICY</h1>
<p className="text-[#000] dark:text-[#A6A6A6]">By installing, accessing and/or playing our games or interacting with our services you agree to the collection and use of your information in accordance with the terms of this privacy policy, please read the following carefully to understand our practices regarding your personal data and how we will treat it. If you do not agree to this privacy policy, please uninstall or do not install, access or play our games or use our services.

<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>2.  INTRODUCTION</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>This   privacy   policy   describes   how   Clausas.com (“we”, “us”, “our” or “Clausas”) collect, use, process and share personal information about you, in accordance with data protection and privacy laws and regulations applicable to us or to our processing of your personal information (“Data Protection Legislation”).</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>This privacy policy together with our any additional terms of use incorporated by reference in the Terms of Use applies to your use of the Services. The Terms of Use are available here.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>This privacy policy will help you understand what personal data we collect from you or your devices when using the Services; how we use and share that personal data; and how you can exercise your privacy rights. If you have any questions about this privacy policy, please contact us using the information in Section 15 below.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>We keep our privacy policy under regular review. If we make changes to this privacy policy, we will change the “Last Updated” date above and post the revised privacy policy. The new privacy policy may be displayed on-screen and you may be required to read and accept the changes to access our Services.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>A separate privacy policy applies in respect of our website (available here)</p>

<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>3.  ABOUT US</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"></p>Clausas is a private independent Game Dev. based in Paris, France. For the purpose of the Data Protection legislation and this policy, we are the “data controller” which means that we are responsible for deciding how we hold and use personal information about you.<br /></p>

<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>4. PARENTS & CHILDREN</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>Where our Services are designed to provide a fun, family-friendly experience for users of all ages, we have categorised such Services as being “Family Friendly” and have taken precautions for younger users who might access those Services. Specifically, for children under the minimum applicable age, which varies by jurisdiction (“Children”), if we collect personal data, it will only be (a) with verified parental consent; or (b) as necessary to support our internal operations, in each case, in compliance with the US’s Children’s Online Privacy Protection Act (COPPA) and the U.K.’s Children’s Code. We also take steps to prevent Children from disclosing personal data publicly while using those Services.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>If you are a parent or legal guardian of a child using any Services designated as Family Friendly, you may contact us to review your child’s personal data, ask us to delete it, or tell us to stop collecting or using it. To do so, please contact us using the information provided in section 15 below.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>If our Services are not categorised as Family Friendly, the Services are not intended for or directed at, children and we do not knowingly or intentionally collect or process data relating to any such children who may be using the Services.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>To see whether a particular Service is categorised as Family Friendly, please refer to Schedule A (Service Specific Details).</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>Kids Web Services
Where we facilitate verified parental consent for Children to access certain features of our games, we work with Epic Games Inc.’s Kids Web Services to ensure that the verification and approval processes are robust, user-friendly and in compliance with applicable law.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>Kids Web Services is a leading provider of kid-friendly registrations and digital experiences, as well as parental management tools, across the web and mobile ecosystem in compliance with privacy regulations. Kids Web Services is certified as COPPA-compliant by the kidSAFE Seal Program, an FTC-Approved COPPA Safe Harbor Program. The kidSAFE Seal Program is an independent safety certification service and seal-of-approval program designed exclusively for children-friendly websites and technologies, including child-targeted game sites, educational services, virtual worlds, social networks, mobile apps, connected products, and other similar interactive services and technologies. To learn more about COPPA, you may consult this one-page informational guide from the kidSAFE Seal Program: www.kidsafeseal.com/knowaboutcoppa.html.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>ESRB Privacy Certified
We are also a member of ESRB Privacy Certified, a full-service, annual membership privacy consulting and seal program for websites, mobile apps, and internet-connected products. We work with the ESRB Privacy Certified team in respect of a number of our Games to ensure our compliance with COPPA and other privacy regulations.</p>

<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>5. INFORMATION THAT WE COLLECT OR RECEIVE</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>We collect and receive information from and about you by different means as described below.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>Games
If you play our Games then we may collect and use the following additional personal information set out in this section.</p>

<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>Information provided by you

We collect the following information if provided by you:

Username: When you play our Games you will have the option to create a display name. You should not use your actual name, email address or any identifiable information for this online username because it may be visible to other users of the Services. For Games which are categorised as being Family Friendly any users who are Children will either be restricted from creating a username or their custom username will not be displayed to other users, in each case, to prevent potential disclosure of their personal data to other users. Some of our Games will allow parents to change this setting for Children if they provide verified parental consent.
Date of birth: For some of our Games and Services, we may ask you for your date of birth the first time you run the Service to determine what age appropriate features and operations are enabled.
Information automatically collected</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
We also collect the following information automatically when you play our Games and use our Services:

Device and Location Data: Information about your device and network, including your Internet Protocol address (IP address); device ID and name; Apple’s ID for Advertisers (IDFA) or Google Advertising ID (GAID); your general location (i.e., nearest City), which we determine from your mobile device settings; type of device; operating system; type of mobile browser; time zone settings; and platform;
Profile and Usage Data: Includes, your account username and password; information about your use of the Games, including when and how you installed the Games; when you use the Game; the content you view; your Game usage data, including the Games played, Game performance, and scores; and purchase history, including all purchases made within the Games;
Performance and diagnostic data: Includes information such as software errors and load time details, detailed information about crashes or information if the software runs slower than expected; and
Marketing and Communication Data: Includes your preferences for receiving marketing communications from us and third party websites and services and your communication preferences.
Information provided by others</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Third Party Platforms: We may also receive information about you from third parties such as (a) your platform ID and your display name from platforms which you download the game from (such as Steam, PlayStation, Nintendo); and (b) your social media account ID from social media sites, services, and applications (“Social Media Sites“) which you choose to connect to from within our Services.
Optional features
Our Services may have optional features from time to time which, if used by you, require us to collect additional information to provide such features. You will be notified of such collection, as appropriate. If you choose not to provide the information needed to use a feature, you may be unable to use these features.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Marketing tracking

If you permit marketing tracking on your device, we will be able to better monitor the effectiveness of our marketing campaigns by potentially attributing your installation of the mobile app to any interaction you made with an advertisement outside our Services.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Users who we have identified as Children will have marketing tracking automatically disabled. Please note that due to AppStore requirements on iOS devices, Children may still see Apple’s “App Tracking Transparency” pop-up suggesting marketing tracking may be enabled. However, in accordance with COPPA, regardless of a Child’s response to such pop-up, our Game disables any marketing tracking for Children.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Other
We will collect and process information about you which you provide to us when you contact us by email, telephone, post or social media in relation to the Services such as for the purposes of customer support, completing a survey or entering a competition.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
If you submit an enquiry to us then, depending on the nature of your enquiry, we may collect further details from you so as to understand the context in which you are making the enquiry and/or to understand which of our Services may be of interest to you.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
This information may include the following:

Identity Data: Includes your personal details such as your name, address and other contact details;
Communication Data: Includes details of any contact we have had with you in relation to the provision, or the proposed provision, of our Services; our correspondence and communications with you; information about any complaints and enquiries you may have submitted to us;
Service Data: Includes details of any Services you have received from us;
Marketing: Includes information from any research or surveys conducted by us in which you may have participated; information from any marketing activities to which you may have responded or in which you may have participated;
Public: Information we receive from other sources such as publicly available information.
Aggregated and anonymised data
We may also collect, use and share information that does not identify you individually, including aggregated or anonymised information that we create or collect from other sources for any purpose. This information helps us better understand larger groups of users. It could be derived from your personal data but is not considered personal data under the Data Protection Legislation as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific feature of the Services. However, if we combine or connect any aggregated data which identifies you, we will treat the combined data as personal data and use it in in accordance with this privacy policy. But please note that this privacy policy does not restrict our ability to process information that does not individually identify you, and we may use and disclose aggregated or de-identified information for any reason permitted by law.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
No special category data
We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). We also do not collect any information about criminal convictions and offences.
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>6. HOW WE USE YOUR DATA</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
We will only use your personal information for certain specified reasons and only where we have a lawful basis to do so. The lawful bases are as follows:
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Contract: We may process your personal data for purposes necessary for the performance of our contract with you, or for steps preparatory to entering into a contract with you, and to comply with our legal obligations.
Legitimate Interests: We may process your personal data for the purposes of our own legitimate interests provided that those interests do not override any of your own interests, rights and freedoms which require the protection of your personal data. This includes processing for marketing, business development, statistical and management purposes.
Legal Obligation: We may process your personal data to meet our legal obligations under applicable law (e.g. financial reporting).
Consent: We may process your personal data for certain additional purposes with your consent, and in these limited circumstances where your consent is required for the processing of your personal data then (i) we will request such consent from you separately, and (ii) you have the right to withdraw your consent to processing for such specific purposes.
Please note that we may process your personal data on more than one lawful bases depending on the specific purpose for which we are using your personal data.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Situations in which we will use your personal data
We may use the personal information we collect about you to:

Services: make the Services available to you, including to operate the Services; to communicate with you about your access to and use of the Services; to respond to your enquiries; to fulfil your orders and requests; to provide troubleshooting and other technical support; for other customer service and support purposes; and to tailor content we send or display while you are using our Services (e.g., for your geographic area);
Security and integrity of the Services: verify your identity; to detect and prevent fraud, cheating and unauthorised activities; to facilitate software updates; to secure our systems and the Services; to prevent hacking, cheats and spamming; to enforce the terms of the Terms of Use and other applicable terms; and to protect the rights and safety of our users;
Development: analyse and improve our Services, including to better understand how you access and use the Services; to evaluate and improve the Services; to develop new games, features, offerings and services; and for other internal research and analytical purposes.
Marketing and Advertising: improve our advertising, marketing and promotions; to evaluate, measure and improve the effectiveness of our advertising campaigns; to contact you about our Services or our other products or information we think may interest you; and at times, to administer promotions and contests;
Legal: defend our legal rights, including to manage and respond to actual and potential legal disputes and claims and to otherwise establish, defend or protect our rights or interests, including in the context of anticipated or actual litigation with users or third parties; and
Compliance: conduct necessary auditing, reporting, corporate governance, and internal operations; and to comply with legal obligations, including as part of a judicial proceeding; to respond to a warrant, court order, or other legal process; or as part of an investigation or request, whether formal or informal, from law enforcement or a governmental authority.
If you refuse to provide us with certain information when requested, we may not be able to perform the some or all of the Services. Alternatively, we may be unable to comply with our legal or regulatory obligations. We may also process your personal information without your knowledge or consent, in accordance with this notice, where we are legally required to do so.
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>7. INFORMATION SHARING AND TRANSFERS</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
This section explains how we may share the information we collect with third parties. This section applies to all of our Services and should be read in conjunction with the Service Specific Details at Schedule A.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Why might you share my personal information with third parties?
We will share your personal information with third parties where it is necessary to complete any transaction or to provide you with the Services you have requested. We also share personal information: with third party service providers and vendors working on our behalf, when we are required by law or to respond to legal process, to protect our customers, to protect lives; to maintain the security of our Services; and to protect the rights of Marmalade and our customers.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Which third-party service providers process my personal information?
“Third parties” includes third-party service providers. The following activities are carried out by third- party service providers: IT, server and data storage services, software development services, professional advisory services, online and mobile analytics and performance diagnostics services, marketing, advertising and campaign services and administration services.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
All of our third-party service providers are required to take commercially reasonable and appropriate security measures to protect your personal information. We only permit our third-party service providers to process your personal data for specified purposes and in accordance with our instructions.
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
The third parties we engage varies depending on the Services. Please refer to Schedule (Service Specific Details).
</p>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
What about other third parties?
We may also share your personal information with other third parties, for example with any relevant licensors of the properties we use in the Services, in the context of a possible sale or restructuring of our business, where an external organisation conducts an audit or undertakes quality checks for us, or where sharing with third parties such as counsel or other lawyers, accountants and experts is appropriate in order to provide our services to you. We may also need to share your personal information with a regulator or to otherwise comply with the law. We may disclose your personal information in order to protect our rights or property or those of our clients or others; and this includes exchanging information with other companies and organisations for the purposes of fraud prevention, compliance with anti-money laundering and ‘know your client’ requirements, and credit risk reduction.
</p>
<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>8. DATA RETENTION</h1>
We will only retain your personal data for as long as is necessary to fulfil the purposes for which it is collected. When assessing what retention period is appropriate for your personal data, we take into consideration:

our requirements;
the purposes for which we originally collected the personal data;
the lawful grounds on which we based our processing;
the types of personal data we have collected;the amount and categories of your personal data; and
whether the purpose of the processing could reasonably be fulfilled by other means.
In some circumstances (for example when we collect statistical data about users’ actions and patterns when using particular Services) we may anonymise your personal information so that it can no longer be associated with you, in which case we may use such information without further notice to you.

In some circumstances you can also ask us to delete your data: see below for further information.

<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>9. DATA SECURITY</h1>
The security of your personal data is important to us. We implement technical, administrative, and physical security measures to protect your personal data from unauthorised access, use, and disclosure. This includes, for example, encrypted communications, physically secured rooms, firewalls, password protection systems. Despite these efforts, we cannot completely guarantee the security of your personal data. If we learn that your unencrypted personal data has been compromised by a data breach, we will notify you consistent with applicable laws.

<h1 className="text-[#000] dark:text-[#A6A6A6]" style={{fontWeight: 700, marginBottom:20, marginTop:20}}>10. YOUR PRIVACY RIGHTS</h1>
<p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Under certain circumstances by law, you have the right to:
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Request access to your personal data. This enables you to receive details of the personal information we hold about you and to check that we are processing it lawfully.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Request correction of the personal data we hold about you. You may ask us to correct personal data we hold about you if it is inaccurate or incomplete.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>

Request erasure of your personal data. In some circumstances, such as where we no longer need it or if you withdraw your consent (where applicable), you may ask us to delete or remove your personal data where you have exercised your right to object to processing (see below).
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Request the restriction processing of your personal data. You may ask us to suspend the processing of your personal data in certain circumstances, such as where you contest the accuracy of the data or object to our use or stated legal basis.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Object to our processing of your personal data. Where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground. You also have the right to object where we are processing your personal information for direct marketing purposes.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Request the transfer of your personal information to another party.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
Withdraw your consent. If your personal data is processed based on your consent, you have the right to withdraw that consent at any time, in which case we must stop processing the data unless we have another legitimate basis for process for doing so in law. You may also ask us to explain the consequences of denying or withdrawing your consent.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
If you wish to exercise any of the above rights, please contact us using the information in section 15 below.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
You will not usually have to pay a fee to access your personal information (or to exercise any of the other rights). However, we may charge a reasonable fee if your request for access is clearly unfounded or excessive. Alternatively, we may refuse to comply with the request in such circumstances.
</p><p className="text-[#000] dark:text-[#A6A6A6]"style={{marginBottom:20}}>
We may need to request specific information from you to help us confirm your identity and ensure your right to access the information (or to exercise any of your other rights). This is another appropriate security measure to ensure that personal information is not disclosed to any person who has no right to receive it.
</p>
            </div>
            </div>
            </div>


          {/* call footer */}
          <Footer condition={false} bg={"#FFFF"} />
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
